import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../../theme/constants';

export const useLandingPagesStyles = makeStyles(() => ({
  headheadingSite: {
    color: '#080c2d',
    fontSize: '2.8rem',
    fontWeight: 900,
    textAlign: 'center',
    letterSpacing: '4px',
  },
  headheadingContent: {
    color: '#080c2d',
    fontSize: '1.4rem',
    fontWeight: 400,
    textAlign: 'center',
    letterSpacing: '0.5px',
  },
  homeContainerWhiteNarrow: {
    display: 'flex',
    maxWidth: '1240px',
    backgroundColor: COLOR_WHITE,
    // backgroundColor: '#f7ca71',
    marginTop: '10px',
    margin: '0 auto',
  },

  homeContainerBlueNarrow: {
    display: 'flex',
    maxWidth: '1240px',
    backgroundColor: '#45a2f9',
    // alignItems: 'center',
    margin: '0 auto',
    marginTop: '10px',
  },

  homeContainerWhiteMedium: {
    display: 'flex',
    maxWidth: '1440px',
    backgroundColor: COLOR_WHITE,
    // backgroundColor: '#f7ca71',
    marginTop: '10px',
    margin: '0 auto',
  },

  homeContainerBlueMedium: {
    display: 'flex',
    maxWidth: '1440px',
    backgroundColor: '#ebf0f4',
    // backgroundColor: '#45a2f9',
    // alignItems: 'center',
    margin: '0 auto',
    marginTop: '10px',
  },
  homeContainerWhiteWide: {
    display: 'flex',
    maxWidth: '1640px',
    backgroundColor: COLOR_WHITE,
    // backgroundColor: '#f7ca71',
    margin: '0 auto',
    marginTop: '10px',
  },

  homeContainerBlueWide: {
    display: 'flex',
    maxWidth: '1640px',
    backgroundColor: '#ebf0f4',
    //    backgroundColor: '#45a2f9',
    margin: '0 auto',
    marginTop: '10px',
  },

  mastheadlightblue: {
    backgroundColor: '#ebf0f4',
    padding: '20px 30px 20px 0px',
  },
  mastheadlightblueLong: {
    backgroundColor: '#ebf0f4',
    padding: '20px 20px 150px 0px',
    '@media (max-width:868px)': {
      padding: '20px 30px 10px 0px',
      backgroundColor: '#ebf0f4',
    },
  },

  mastheadWhite: {
    // backgroundColor: '#ebf0f4',
    padding: '20px 0px 20px 0px',
    margin: '0px 0px 0px 0px',
    '@media (max-width:868px)': {
      padding: '20px 30px 10px 0px',
      backgroundColor: '#ebf0f4',
    },
  },
  boxingallblue: {
    display: 'inline-flex',
    maxWidth: '1260px',
    padding: '2em 1em 3em 1em',
  },
  siteTitle: {
    fontSize: '2.6rem',
    fontWeight: '600',
    color: COLOR_WHITE,
    marginTop: '50px',
    animation: 'textwelle 0.6s',
  },
  siteTitleContent: {
    fontSize: '1.3rem',
    fontWeight: '400',
    color: COLOR_WHITE,
    marginLeft: '0px',
    animation: 'textwelle 0.5s',
  },
  container: {
    minHeight: '200px',
  },

  contentBold: {
    fontSize: '1.1rem',
    fontWeight: '600',
    color: '#080c2d',
    letterSpacing: '0.03rem',
    marginLeft: '3px',
  },

  boxContentInformation: {
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'left',
    flexWrap: 'wrap',
    width: '100%',
    padding: '0px 0px 0px 0px ',
  },

  boxImagesHead: {
    minHeight: '280px',
    maxHeight: '280px',
  },
  headImagesTextPosition: {
    display: 'inline-flex',
    maxWidth: '1440px',
    padding: '4rem 1rem 1rem 0.5rem',
  },
  secondaryContentCost: {
    fontSize: '1.1rem',
    color: '#545372',
    backgroundColor: COLOR_WHITE,
    fontWeight: '400',
    padding: '10px 50px 30px 35px ',
    letterSpacing: '0.03rem',
  },
  secondaryContentBold: {
    fontSize: '1.1rem',
    color: '#545372',
    fontWeight: '600',
    padding: '10px 0px 0px 0px ',
    letterSpacing: '0.03rem',
  },
  containerFooter: {
    minHeight: '100px',
    maxWidth: '1230px',
    margin: '0 auto',
    marginTop: '-30px',
  },
  contentcontainerFooter: {
    fontSize: '1.1rem',
    fontWeight: '500',
    color: '#474646',
    marginTop: '0px',
  },

  contentcontainerFooterLink: {
    fontSize: '0.9rem',
    fontWeight: '500',
    color: '#474646',
    marginTop: '0px',
  },
  /*---------------------------------*/
  contentReportHeadLeft: {
    textAlign: 'left',
    fontSize: '1.0rem',
    fontWeight: '600',
    color: '#545372',
    margin: '2px 2px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '5px 0px 0px 0px',
  },
  contentReportHeadRight: {
    textAlign: 'right',
    fontSize: '1.01rem',
    fontWeight: '600',
    color: '#545372',
    margin: '2px 2px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '5px 0px 0px 0px',
  },
  contentReportHeadCenter: {
    textAlign: 'center',
    fontSize: '1.01rem',
    fontWeight: '600',
    color: '#545372',
    margin: '2px 2px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '5px 0px 0px 0px',
  },
  /*---------------------------------*/
  contentReportLeft: {
    textAlign: 'left',
    fontSize: '1.0rem',
    fontWeight: '400',
    color: '#545372',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },

  contentReportRight: {
    textAlign: 'right',
    fontSize: '1.0rem',
    fontWeight: '400',
    color: '#545372',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportCenter: {
    textAlign: 'center',
    fontSize: '1.01rem',
    fontWeight: '400',
    color: '#545372',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportLeftBold: {
    textAlign: 'left',
    fontSize: '1.01rem',
    fontWeight: '600',
    color: '#545372',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportRightBold: {
    textAlign: 'right',
    fontSize: '1.01rem',
    fontWeight: '600',
    color: '#545372',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportCenterBold: {
    textAlign: 'center',
    fontSize: '1.01rem',
    fontWeight: '600',
    color: '#545372',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentspecialFeatures: {
    display: 'flex',
    textAlign: 'left',
    fontSize: '1.0rem',
    fontWeight: '400',
    color: '#080c2d',
    margin: '12px 2px 2px 2px ',
    letterSpacing: '0.02rem',
  },

  dotSpecialFeatures: {
    color: '#3e7f35',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginRight: '10px',
    marginTop: '2px',
    margin: '0px 0px 0px 0px ',
  },
  guidelineImages: {
    marginTop: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
