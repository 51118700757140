import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import ImageIMacDashboard from '../imagesSettings/ImageIMacDashboard';

const useStyles = makeStyles(() =>
  createStyles({
    imac: {},
    pcaExHold: {
      width: ({ scale }) => `${scale * 300}px`,
    },
    pcaExMain: {
      display: 'block',
      overflow: 'hidden',
      position: 'relative',
      backgroundColor: '#efefef',
      height: ({ scale }) => `${scale * 180}px`,
      border: ({ scale }) => `${scale * 10.8}px solid #2d2d2d`,
      borderTopLeftRadius: ({ scale }) => `${scale * 7.2}px`,
      borderTopRightRadius: ({ scale }) => `${scale * 7.2}px`,
      '&:after': {
        content: '""',
        position: 'absolute',
        top: ({ scale }) => `${scale * -8.9}px`,
        left: '50%',
        WebkitTransform: 'translate(-50%, 0)',
        MsTransform: 'translate(-50%, 0)',
        OTransform: 'translate(-50%, 0)',
        transform: 'translate(-50%, 0)',
        width: ({ scale }) => `${scale * 5.7}px`,
        height: ({ scale }) => `${scale * 5.7}px`,
        backgroundColor: '#959595', // webcam
        borderRadius: '100%',
      },
      '&:before': {
        content: '""',
        opacity: '0',
      },
    },
    pcaExInner: {
      display: 'block',
      '& .react-player__play-icon': {
        display: 'none',
      },
    },
    pcaExSub: {
      display: 'block',
      textAlign: 'center',
    },
    pcaExTop: {
      position: 'relative',
      display: 'block',
      width: ({ scale }) => `${scale * 300}px`,
      height: ({ scale }) => `${scale * 24}px`,
      backgroundColor: '#d4d3d3', // Monitor Bedienteil #e8e8e8'
      borderBottomLeftRadius: ({ scale }) => `${scale * 7.2}px`,
      borderBottomRightRadius: ({ scale }) => `${scale * 7.2}px`,
      opacity: '1',
      zIndex: '2',
      '&:after': {
        content: '""',
        position: 'absolute',
        width: ({ scale }) => `${scale * 12}px`,
        height: ({ scale }) => `${scale * 12}px`,
        top: '50%',
        left: '50%',
        WebkitTransform: 'translate(-50%, -50%)',
        MsTransform: 'translate(-50%, -50%)',
        OTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
        backgroundSize: 'cover',
        backgroundColor: '#fff',
        borderRadius: '10.8px',
      },
    },
    pcaExMid: {
      display: 'block',
      textAlign: 'center',
      height: ({ scale }) => `${scale * 24}px`,
      width: '100%',
      position: 'relative',
      zIndex: '1',
    },
    pcaExPart: {
      display: 'block',
      margin: '0 auto',
      borderBottom: ({ scale }) => `${scale * 30}px solid #b3b0b0`, // Monitor Staender #cdcdcd
      borderLeft: ({ scale }) => `${scale * 15}px solid transparent`,
      borderRight: ({ scale }) => `${scale * 15}px solid transparent`,
      height: '0',
      width: ({ scale }) => `${scale * 90}px`,
    },
    pcaExBot: {
      position: 'relative',
      display: 'block',
      width: ({ scale }) => `${scale * 120}px`,
      height: ({ scale }) => `${scale * 9}px`,
      margin: '0 auto',
      backgroundColor: '#848181', // Monitor Fuss #e8e8e8
      borderRadius: '6px 6px 0 0',
      opacity: '1',
      zIndex: '2',
    },
  }),
);

export function Imac(props) {
  const { scale } = props;

  const classes = useStyles({ scale });
  return (
    <div className={classes.imac}>
      <div className={classes.pcaExHold}>
        <div className={classes.pcaExMain}>
          <div className={classes.pcaExInner}>
            <ImageIMacDashboard />
          </div>
        </div>
        <div className={classes.pcaExSub}>
          <div className={classes.pcaExTop} />
          <div className={classes.pcaExMid}>
            <div className={classes.pcaExPart} />
          </div>
          <div className={classes.pcaExBot} />
        </div>
      </div>
    </div>
  );
}

Imac.propTypes = {
  scale: PropTypes.number,
};

Imac.defaultProps = {
  scale: 1,
};
