import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ImageHandshake } from '../imagesSettings/ImageHandshake';
import { ImageBids } from '../imagesSettings/ImageBids';
import { ImageAddJob } from '../imagesSettings/ImageAddJob';
import { useHomePageStyles } from './styles';

const JustCreate = () => {
  const { t } = useTranslation();
  const classes = useHomePageStyles();
  return (
    <Box className={`${classes.mastheadlightblue} ${classes.paddingBottom2} `}>
      <Grid id="specialFeatures" container spacing={2} className={classes.homeContainerBlueMedium}>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={0}>
            <Typography className={classes.contentHeadCenter}>{t('trans.justCreateHead')}</Typography>
            <Typography className={classes.contentHeadCategoryCenter}>{t('trans.justCreateContent')}</Typography>
          </Box>
        </Grid>

        <Grid container spacing={2} className={classes.justCreateBoxHead}>
          <Grid item lg={4} md={4} xs={12}>
            <Box className={classes.justCreateBox}>
              <Box mt={2}>
                <ImageAddJob className={classes.schatten} />
              </Box>
              <Box className={classes.boxJustCreate}>
                <Box>
                  <Typography className={classes.justCreateNumber}>1</Typography>
                </Box>
                <Box>
                  <Typography className={classes.justCreateContentNumber}>{t('trans.justCreateContentOne')}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item lg={4} md={4} xs={12}>
            <Box className={classes.justCreateBox}>
              <Box mt={2}>
                <ImageBids />
              </Box>
              <Box className={classes.boxJustCreate}>
                <Box>
                  <Typography className={classes.justCreateNumber}>2</Typography>
                </Box>
                <Box>
                  <Typography className={classes.justCreateContentNumber}>{t('trans.justCreateContentTwo')}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item lg={4} md={4} xs={12}>
            <Box className={classes.justCreateBox}>
              <Box mt={2}>
                <ImageHandshake />
              </Box>
              <Box className={classes.boxJustCreate}>
                <Box>
                  <Typography className={classes.justCreateNumber}>3</Typography>
                </Box>
                <Box>
                  <Typography className={classes.justCreateContentNumber}>
                    {t('trans.justCreateContentThree')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JustCreate;
