import React from 'react';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

import styled from 'styled-components';
import ReactPlayer from 'react-player/file';
import { useHomePageStyles } from './styles';
import RegisterForFreeButton from '../Button/registerForFreeButton';
import LandingPagesInstructionGuide from '../LandingPages/landingPagesInstructionGuide';

const LinkText = styled(Link)`
  display: inline-block;
  z-index: -2;
  text-decoration: none;
  color: #ea5e2a;

  &:hover {
    color: #ea5e2a;
    text-decoration: none;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    text-decoration: none;
  }
`;

const SpecialFeatures = () => {
  const { t } = useTranslation();
  const classes = useHomePageStyles();

  return (
    <Box className={classes.mastheadWhite}>
      <Grid id="special-features" container spacing={4} className={classes.homeContainerWhiteNarrow}>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={0}>
            <Typography className={classes.contentHeadCenter}>{t('trans.specialFeaturesHeadBlog')}</Typography>
          </Box>
          <Box ml={0}>
            <Typography className={classes.contentHeadCategoryCenter}>
              {t('trans.specialFeaturesHeadComtent')}
            </Typography>
          </Box>
        </Grid>

        <Box ml={2}>
          <Hidden smDown>
            <Grid container spacing={0}>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent1')}
                </Typography>

                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent2')}
                </Typography>

                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent3')}
                </Typography>
                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent4')}
                </Typography>
                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent13')}
                </Typography>
              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent5')}
                </Typography>

                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent6')}
                </Typography>
                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent7')}
                </Typography>

                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent8')}
                </Typography>
                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent14')}
                </Typography>
              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent9')}
                </Typography>

                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent10')}
                </Typography>

                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent11')}
                </Typography>
                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent12')}
                </Typography>
                <Typography className={classes.contentspecialFeatures}>
                  <DoneOutlineIcon className={classes.dotSpecialFeatures} />
                  {t('trans.specialFeaturesContent15')}
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
          <Box visibility="hidden" position="absolute" mt={4}>
            <Typography className={classes.contentReportLeft}>
              {t('trans.specialFeaturesContent16')}
              <LinkText to="/safety-instruction/" title="Highlights und viel Wissenswertes">
                {t('trans.specialFeaturesLink1')}
              </LinkText>
              {t('trans.specialFeaturesContent17')}
              <LinkText to="/initial-instruction/" title="Highlights und viel Wissenswertes">
                {t('trans.specialFeaturesLink2')}
              </LinkText>
              {t('trans.specialFeaturesContent18')}
              <LinkText to="/employee-instruction/" title="Highlights und viel Wissenswertes">
                {t('trans.specialFeaturesLink3')}
              </LinkText>
              {t('trans.specialFeaturesContent19')}

              {t('trans.specialFeaturesContent20')}
              <LinkText to="/visitor-instruction/" title="Highlights und viel Wissenswertes">
                {t('trans.specialFeaturesLink4')}
              </LinkText>
              {t('trans.specialFeaturesContent21')}
              {t('trans.specialFeaturesContent22')}

              {t('trans.specialFeaturesContent25')}
              <LinkText to="/instruction-archive/" title="Highlights und viel Wissenswertes">
                {t('trans.specialFeaturesLink5')}
              </LinkText>
              {t('trans.specialFeaturesContent26')}
              {t('trans.specialFeaturesContent27')}
              <LinkText to="/proof-of-instruction/" title="Highlights und viel Wissenswertes">
                {t('trans.specialFeaturesLink6')}
              </LinkText>
              {t('trans.specialFeaturesContent28')}
            </Typography>
            <Box>
              <LandingPagesInstructionGuide />
            </Box>
          </Box>

          <Grid item lg={12} md={12} xs={12}>
            <Box mt={4}>
              <Typography className={classes.contentReportHeadLeft}>{t('trans.specialFeaturesFooter')}</Typography>
              <Typography className={classes.contentReportLeft}>
                {t('trans.specialFeaturesFooterFreeContent1')}
              </Typography>

              <Box className={classes.videoWrap}>
                <ReactPlayer
                  controls
                  width="100%"
                  height="auto"
                  url="https://space.d-simple-storage.com/static/videos/umo.mp4"
                  config={{
                    file: {
                      tracks: [{ kind: 'captions', src: '/umo_gesamt.vtt' }],
                    },
                  }}
                />
              </Box>

              <Box className={classes.contentspecialFeaturesbutton}>
                <RegisterForFreeButton />
              </Box>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default SpecialFeatures;
