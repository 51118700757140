import * as React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/layout';
import { Home } from '../components/HomePage';
import Seo from '../components/seo';

const IndexPage = ({ location, pageContext }) => (
  <Layout>
    <Seo title="Home" lang={pageContext.language} />
    <Home location={location} />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
