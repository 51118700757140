import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import styled from 'styled-components';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { useLandingPagesStyles } from './styles';

const LinkText = styled(Link)`
  display: inline-block;
  z-index: -2;
  text-decoration: none;
  color: #ea5e2a;

  &:hover {
    color: #ea5e2a;
    text-decoration: none;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    text-decoration: none;
  }
`;

const LandingPagesInstructionGuide = () => {
  const { t } = useTranslation();
  const classes = useLandingPagesStyles();
  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} xs={12}>
          <Box>
            <Typography className={classes.contentReportLeft}>
              {t('trans.landingpage instruction-created1')}
              <LinkText to="/how-to-create-an-instruction" title={t('trans.landingpage instruction-created title')}>
                {t('trans.landingpage instruction-created linktext')}
              </LinkText>
              {t('trans.landingpage instruction-created2')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default LandingPagesInstructionGuide;
