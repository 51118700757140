import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import ImagesProductionOwnInstrction from '../imagesSettings/Tour/imagesProductionOwnInstrction';
import { useHomePageStyles } from './styles';

const Features = () => {
  const { t } = useTranslation();
  // const classes = useHomePageStyles();
  const classes = useHomePageStyles();

  return (
    <Box className={classes.mastheadWhite}>
      {/* <Grid container spacing={4} className={classes.contentboxOurStrengths}> */}
      <Grid container spacing={4} className={classes.homeContainerWhiteMedium}>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={1}>
            <Typography className={classes.contentHeadCenter}>{t('trans.featuresHeadBlog')}</Typography>
          </Box>

          <Typography className={classes.contentHeadCategoryCenter}>{t('trans.featuresContent')}</Typography>

          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} xs={12}>
                <Box mt={0}>
                  <ImagesProductionOwnInstrction />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Box mt={0}>
                  <Typography className={classes.contentReportHeadLeft}>{t('trans.FeaturesDesignerHead')}</Typography>

                  <Typography className={classes.contentReportLeft}>{t('trans.FeaturesDesigner-1')}</Typography>
                </Box>
                <Box mt={2}>
                  <Typography className={classes.contentReportLeft}>{t('trans.FeaturesDesigner-2')}</Typography>
                </Box>
                <Box mt={2}>
                  <Typography className={classes.contentReportHeadLeft}>
                    {t('trans.FeaturesDesignerConstructionKitHead')}
                  </Typography>
                  <Typography className={classes.contentReportLeft}>{t('trans.FeaturesDesigner-3')}</Typography>
                </Box>
                <Box mt={2}>
                  <Typography className={classes.contentReportLeft}>{t('trans.FeaturesDesigner-4')}</Typography>
                </Box>
              </Grid>

              {/* -------------------------------------------------------- */}
              {/* -------------------------------------------------------- */}
              {/* -------------------------------------------------------- */}

              <Grid item lg={12} md={12} xs={12}>
                <Box mt={2}>
                  <Typography className={classes.contentReportLeft}>{t('trans.FeaturesFooter-1')}</Typography>
                </Box>
              </Grid>
              {/* -------------------------------------------------------- */}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Features;
