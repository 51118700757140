import React from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useI18next, useTranslation, Link } from 'gatsby-plugin-react-i18next';
import ImagesPerformance from '../imagesSettings/OurStrengths/imagesPerformance';
import ImagesDsvgo from '../imagesSettings/OurStrengths/imagesDsvgo';
import ImagesTeaser from '../imagesSettings/OurStrengths/imagesTeaser';

import { useHomePageStyles } from './styles';
import { EmbeddedAppPage } from '../EmbeddedAppPage';

const LinkText = styled(Link)`
  display: inline-block;
  z-index: -2;
  text-decoration: none;
  color: #ea5e2a;

  &:hover {
    color: #ea5e2a;
    text-decoration: none;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    text-decoration: none;
  }
`;

const OurStrengths = () => {
  const { t } = useTranslation();
  const classes = useHomePageStyles();

  const [isOpen, setIsOpen] = React.useState(false);

  const { language } = useI18next();

  return (
    <>
      <Box className={classes.mastheadWhite}>
        <Grid container spacing={4} className={classes.homeContainerWhiteMedium}>
          <Grid item lg={12} md={12} xs={12}>
            <Box mt={1}>
              <Typography className={classes.contentHeadCenter}>{t('trans.ourStrengthsHeadBlog')}</Typography>
            </Box>
          </Grid>

          <Grid item lg={4} md={6} xs={12}>
            <ImagesTeaser />
            <Typography className={classes.contentourStrengthsHead}>{t('trans.ourStrengthsHeadThree')}</Typography>
            <Typography className={classes.contentourStrengthsHeadContent}>
              {t('trans.ourStrengthsContentThree')}
            </Typography>
            <Typography className={classes.contentourStrengthsContent}>
              {t('trans.ourStrengthsDescriptionThree')}
            </Typography>

            <Box mt={2} justifyContent="center">
              <Button onClick={() => setIsOpen(true)} variant="outlined" color="secondary">
                {t('trans.previewModules')}
              </Button>
            </Box>
          </Grid>

          <Grid item lg={4} md={6} xs={12}>
            <ImagesDsvgo />
            <Typography className={classes.contentourStrengthsHead}>{t('trans.ourStrengthsHeadTwo')}</Typography>
            <Typography className={classes.contentourStrengthsHeadContent}>
              {t('trans.ourStrengthsContentTwo')}
            </Typography>
            <Typography className={classes.contentourStrengthsContent}>
              {t('trans.ourStrengthsDescriptionTwo')}
            </Typography>
          </Grid>

          <Grid item lg={4} md={6} xs={12}>
            <ImagesPerformance />
            <Typography className={classes.contentourStrengthsHead}>{t('trans.ourStrengthsHeadOne')}</Typography>
            <Typography className={classes.contentourStrengthsHeadContent}>
              {t('trans.ourStrengthsContentOne')}
            </Typography>
            <Typography className={classes.contentourStrengthsContent}>
              {t('trans.ourStrengthsDescriptionOne')}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} xs={12}>
            <Typography className={classes.contentourStrengthsFooter}>
              {t('trans.ourStrengthsContentLink')}{' '}
              <LinkText to="/product">
                <span> {t('trans.ourStrengthsLinkProduct')}</span>
              </LinkText>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <EmbeddedAppPage
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        basePath="umo/view/templates"
        mode="full-screen"
        token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiaW50ZXJuYWwtYXV0aCIsIm9yZ2FuaXNhdGlvbklkIjoiNjIyNjVhZWQyN2JhYzUwMDEzNDg0OGY0IiwiaW50ZXJuYWxBdXRoUGVybWlzc2lvbnMiOlt7InNsdWciOiJ1bW9fdGVtcGxhdGVzX2xpc3RfZmluZCIsInBlcm1pdHRlZCI6dHJ1ZX1dLCJpYXQiOjE3MjI1MzkxMDYsImV4cCI6MTg4MDMyNzEwNn0._yg07QPW1fI0v9KBNXF5uj3l_mx_fPiNrib1S5kWFBY"
        // LOCAL
        // token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiaW50ZXJuYWwtYXV0aCIsIm9yZ2FuaXNhdGlvbklkIjoiNjE0M2I3N2IxMThmY2FkNjg5MTI0ZmU3IiwiaW50ZXJuYWxBdXRoUGVybWlzc2lvbnMiOlt7InNsdWciOiJ1bW9fdGVtcGxhdGVzX2xpc3RfZmluZCIsInBlcm1pdHRlZCI6dHJ1ZX1dLCJpYXQiOjE3MjI1Mzc5ODAsImV4cCI6MTg4MDMyNTk4MH0.J5aXEtPKwLr4H2e7IpxxLOGpiIfKhNErckq9Kj1R7J8"
        title="Unterweisungsmodule von UMO"
        lng={language}
        padding="32px"
        background="#fcfcfc"
      />
    </>
  );
};

export default OurStrengths;
