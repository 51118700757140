import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';

import { AccountCircle } from '@material-ui/icons';
import { useHomePageStyles } from './styles';
import CalculatorButton from '../Button/calculatorButton';
import CostsAccordion from './costsAccordion';

const Costs = (props) => {
  const { t } = useTranslation();
  const classes = useHomePageStyles();

  const { location } = props;

  const [lohn, setlohn] = useState('');
  const [benutzer, setbenutzer] = useState('');
  const [schulung, setschulung] = useState('');

  const handleChangelohn = (event) => {
    setlohn(event.target.value);
  };
  const handleChangebenutzer = (event) => {
    setbenutzer(event.target.value);
  };
  const handleChangeschulung = (event) => {
    setschulung(event.target.value);
  };

  const betrag1 = benutzer * lohn * schulung;
  const betrag2 = betrag1 / 3;
  const betrag3 = betrag1 - betrag2;

  const betraga = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(betrag1);

  const betragb = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(betrag2);

  const betragc = new Intl.NumberFormat(t('trans.IntlNumberFormat'), {
    style: 'currency',
    currency: 'EUR',
  }).format(betrag3);

  useEffect(() => {
    if (betrag3 !== 0) {
      const dataLayer = window.dataLayer || [];

      dataLayer.push({
        event: 'form_change_calculator_simple',
        calcCostsPresence: betrag1,
        calcCostsUmo: betrag2,
        calcSavings: betrag3,
      });
    }
  }, [betrag3, betrag2, betrag1]);

  return (
    <>
      <Box id="price-calculator-simple-site" className={classes.mastheadlightblue}>
        <Grid container spacing={4} className={classes.homeContainerBlueMedium}>
          <Grid item lg={12} md={12} xs={12}>
            <Box mt={1}>
              <Typography className={classes.contentHeadCenter}>{t('trans.costsCalculatorContentHead1')}</Typography>
              <Box mt={0}>
                <Typography className={classes.contentHeadCategoryCenter}>
                  {t('trans.costsCalculatorContentHead2')}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item lg={8} md={6} xs={12}>
            <CostsAccordion location={location} />
          </Grid>

          <Grid item lg={4} md={6} xs={12}>
            <Box boxShadow={1} mt={0} className={classes.boxProductionCost} id="price-calculator-simple">
              <Typography className={classes.costBoxHead}>{t('trans.costsCalculatorHead1')}</Typography>
              <Typography className={classes.costBoxContentHead}>{t('trans.costsCalculatorHead2')}</Typography>
              <Divider className={classes.dividerCostBox} />
              <Typography className={classes.costBoxContentTop}>{t('trans.costsCalculatorContent1')}</Typography>
              <Typography className={classes.costBoxContent}>{t('trans.costsCalculatorContent2')}</Typography>
              <Typography className={classes.costBoxContent}>{t('trans.costsCalculatorContent3')}</Typography>

              <Box mt={4} mb={4}>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    id="outlined-number"
                    value={lohn}
                    label={t('trans.costsCalculatorFieldContent1')}
                    type="number"
                    onChange={handleChangelohn}
                    style={{ margin: 4, maxWidth: '30%', backgroundColor: '#efefef' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EuroSymbolIcon className={classes.iconcost} />
                        </InputAdornment>
                      ),
                      style: {
                        letterSpacing: '0.03rem',
                        fontSize: '0.9rem',
                        height: '40px',
                        backgroundColor: '#efefef',
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                  <TextField
                    id="outlined-number"
                    value={benutzer}
                    label={t('trans.costsCalculatorFieldContent2')}
                    type="number"
                    onChange={handleChangebenutzer}
                    style={{ margin: 4, maxWidth: '30%', backgroundColor: '#efefef' }}
                    InputProps={{
                      style: {
                        letterSpacing: '0.03rem',
                        fontSize: '0.9rem',
                        height: '40px',
                        backgroundColor: '#efefef',
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle className={classes.iconcost} />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />

                  <TextField
                    id="outlined-number"
                    value={schulung}
                    label={t('trans.costsCalculatorFieldContent3')}
                    type="number"
                    onChange={handleChangeschulung}
                    style={{
                      margin: 4,
                      maxWidth: '30%',
                      backgroundColor: '#efefef',
                    }}
                    InputProps={{
                      style: {
                        letterSpacing: '0.03rem',
                        fontSize: '0.9rem',
                        height: '40px',
                        backgroundColor: '#efefef',
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocalLibraryIcon className={classes.iconcost} />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Box>

              <Box className={classes.resultBoxCost}>
                <Box className={classes.boxleft}>
                  <Typography className={classes.resultContent}>{t('trans.costsCalculatorContent4')}</Typography>
                </Box>
                <Box className={classes.boxright}>
                  <Typography className={classes.resultRed}>{betraga}</Typography>
                </Box>
              </Box>

              <Box className={classes.resultBoxCost}>
                <Box className={classes.boxright}>
                  <Typography className={classes.resultContent}>{t('trans.costsCalculatorContent5')}</Typography>
                </Box>

                <Box className={classes.boxright}>
                  <Typography className={classes.resultGreen}>{betragb}</Typography>
                </Box>
              </Box>
              <Box mt={2}>
                <Typography className={classes.costBoxContentHeadFooter}>
                  {t('trans.costsCalculatorContent6')}
                  <Box className={classes.boxContentHeadFooterresult}>
                    {/* eslint-disable-next-line max-len */}
                    <Typography className={classes.costBoxContentHeadFooterresult}>{betragc}</Typography>
                  </Box>
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography className={classes.costBoxContentFooter}>{t('trans.costsCalculatorContent7')}</Typography>
              </Box>
              <Box mt={2} className={classes.boxCalculator}>
                <Typography className={classes.costBoxContentHead}>{t('trans.costsCalculatorContent8')}</Typography>
              </Box>

              <Box className={classes.boxCalculatorcontent}>
                <CalculatorButton />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Costs;
