import React from 'react';
import { Grid, Paper, Box, Typography } from '@material-ui/core';

import { useFeatureBoxStyles } from './styles';
import { List } from '../List';

export function FeatureBox(props) {
  const { title, listItems } = props;
  const classes = useFeatureBoxStyles();
  return (
    <Grid item lg={4} md={4} xs={12}>
      <Paper className={classes.paperContainer}>
        <Box className={classes.head}>
          <Typography>{title}</Typography>
        </Box>

        <Box className={classes.contentFeature}>
          <List listItems={listItems} size="small" />
        </Box>
      </Paper>
    </Grid>
  );
}
