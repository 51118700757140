import { makeStyles } from '@material-ui/core';

export const useFeatureBoxStyles = makeStyles(() => ({
  paperContainer: {
    display: 'block',
    width: '100%',
    height: '320px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  head: {
    backgroundColor: '#ebf0f4',
    fontSize: '0.82rem',
    margin: '0px 0px 0px 00px',
    padding: '10px 0px 10px 10px',
    color: '#080c2d',
    fontWeight: '400',
    textAlign: 'center',
    justifyContent: 'center',
    borderBottom: 'solid #545372 1px',
  },
  contentFeature: {
    fontSize: '0.8rem',
    color: '#545372',
    fontWeight: '400',
    padding: '15px 0px 0px 10px',
  },
}));
