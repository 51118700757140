import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../../theme/constants';

export const useHomePageStyles = makeStyles((theme) => ({
  headImagesPosition: {
    width: '100%',
    minHeight: '750px',
    display: 'flex',
    justifyContent: 'center',
    padding: '0em',
  },
  paddingBottom2: {
    paddingBottom: '16px !important',
  },
  headheadingSite: {
    color: '#080c2d',
    fontSize: '2.8rem',
    fontWeight: 900,
    textAlign: 'center',
    letterSpacing: '4px',
  },
  headheadingContent: {
    color: '#080c2d',
    fontSize: '1.4rem',
    fontWeight: 400,
    textAlign: 'center',
    letterSpacing: '0.5px',
  },
  homeContainerWhiteNarrow: {
    display: 'flex',
    maxWidth: '1240px',
    backgroundColor: COLOR_WHITE,
    marginTop: '10px',
    margin: '0 auto',
  },

  homeContainerBlueNarrow: {
    display: 'flex',
    maxWidth: '1240px',
    backgroundColor: '#45a2f9',
    margin: '0 auto',
    marginTop: '10px',
  },

  homeContainerWhiteMedium: {
    display: 'flex',
    maxWidth: '1440px',
    backgroundColor: COLOR_WHITE,
    // backgroundColor: '#f7ca71',
    marginTop: '10px',
    margin: '0 auto',
  },

  homeContainerBlueMedium: {
    display: 'flex',
    maxWidth: '1440px',
    backgroundColor: '#ebf0f4',
    // backgroundColor: '#45a2f9',
    // alignItems: 'center',
    margin: '0 auto',
    marginTop: '10px',
  },
  homeContainerWhiteWide: {
    display: 'flex',
    maxWidth: '1640px',
    backgroundColor: COLOR_WHITE,
    // backgroundColor: '#f7ca71',
    margin: '0 auto',
    marginTop: '10px',
  },

  homeContainerBlueWide: {
    display: 'flex',
    maxWidth: '1640px',
    backgroundColor: '#ebf0f4',
    //    backgroundColor: '#45a2f9',
    margin: '0 auto',
    marginTop: '10px',
  },

  mastheadlightblue: {
    backgroundColor: '#ebf0f4',
    padding: '20px 30px 20px 0px',
  },
  mastheadlightblueLong: {
    backgroundColor: '#ebf0f4',
    padding: '20px 20px 150px 0px',
    '@media (max-width:868px)': {
      padding: '20px 30px 10px 0px',
      backgroundColor: '#ebf0f4',
    },
  },

  mastheadWhite: {
    // backgroundColor: '#ebf0f4',
    padding: '20px 0px 20px 0px',
    margin: '0px 0px 0px 0px',
    '@media (max-width:868px)': {
      padding: '20px 30px 10px 0px',
      backgroundColor: '#ebf0f4',
    },
  },

  infoPaperBox: {
    width: '70%',
    backgroundColor: COLOR_WHITE,
    margin: '0 auto',
    marginTop: '-120px',
    padding: '20px 35px 50px 35px',
    borderRadius: '4px',
    '@media (max-width:868px)': {
      width: '95%',
      backgroundColor: COLOR_WHITE,
      margin: '0 auto',
      marginTop: '10px',
      padding: '20px 10px 50px 10px',
      borderRadius: '4px',
    },
  },
  /*---------------------------------*/
  contentHeadLeft: {
    textAlign: 'left',
    fontSize: '2.0rem',
    fontWeight: '600',
    color: '#032963',
    marginTop: '2px',
    letterSpacing: '0.05rem',
  },
  contentHeadCenter: {
    textAlign: 'center',
    fontSize: '2.0rem',
    fontWeight: '600',
    color: '#032963',
    marginTop: '2px',
    letterSpacing: '0.05rem',
  },
  /*---------------------------------*/
  contentHeadCategoryLeft: {
    color: '#080c2d',
    fontSize: '1.1rem',
    fontWeight: '500',
    marginTop: '5px',
    textAlign: 'left',
  },
  contentHeadCategoryCenter: {
    color: '#080c2d',
    fontSize: '1.1rem',
    fontWeight: '500',
    marginTop: '5px',
    textAlign: 'center',
  },
  /*---------------------------------*/
  contentReportHeadLeft: {
    textAlign: 'left',
    fontSize: '1.0rem',
    fontWeight: '600',
    color: '#080c2d',
    margin: '2px 2px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '5px 0px 0px 0px',
  },
  contentReportHeadRight: {
    textAlign: 'right',
    fontSize: '1.01rem',
    fontWeight: '600',
    color: '#080c2d',
    margin: '2px 2px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '5px 0px 0px 0px',
  },
  contentReportHeadCenter: {
    textAlign: 'center',
    fontSize: '1.01rem',
    fontWeight: '600',
    color: '#080c2d',
    margin: '2px 2px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '5px 0px 0px 0px',
  },
  /*---------------------------------*/
  contentReportLeft: {
    textAlign: 'left',
    fontSize: '1.0rem',
    fontWeight: '400',
    color: '#080c2d',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportRight: {
    textAlign: 'right',
    fontSize: '1.0rem',
    fontWeight: '400',
    color: '#080c2d',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportCenter: {
    textAlign: 'center',
    fontSize: '1.01rem',
    fontWeight: '400',
    color: '#080c2d',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportLeftBold: {
    textAlign: 'left',
    fontSize: '1.01rem',
    fontWeight: '600',
    color: '#080c2d',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportRightBold: {
    textAlign: 'right',
    fontSize: '1.01rem',
    fontWeight: '600',
    color: '#080c2d',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportCenterBold: {
    textAlign: 'center',
    fontSize: '1.01rem',
    fontWeight: '600',
    color: '#080c2d',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },

  siteTitel: {
    fontSize: '2.6rem',
    fontWeight: '600',
    color: COLOR_WHITE,
    marginTop: '50px',
    animation: 'textwelle 0.5s',
  },
  /*---------------------------------*/

  /*--------------------------------------------------------------------------------------*/
  container: {
    minHeight: '200px',
    margin: '25px 8px 8px 8px ',
  },

  justCreateBoxHead: {
    display: 'flex',
    maxWidth: '1440px',
    padding: '0em 0em 0em 0em',
    margin: '0 auto',
    justifyItems: 'center',
  },
  justCreateBox: {
    display: 'flex',
    minHeight: '200px',
    maxWidth: '400px',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '0 auto',
  },
  boxJustCreate: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: '10px',
  },
  justCreateNumber: {
    textAlign: 'center',
    fontSize: '2.8rem',
    fontWeight: '900',
    color: '#080c2d',
    marginTop: '-20px',
    letterSpacing: '0.02rem',
  },
  justCreateContentNumber: {
    textAlign: 'center',
    fontSize: '1.20rem',
    color: '#545372',
    margin: '-10px 10px 10px 10px',
    letterSpacing: '0.02rem',
  },
  schatten: {
    border: 'solid #BDBDBD 1px',
    boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.5)',
  },

  contentboxOurStrengths: {
    display: 'flex',
    padding: '1em 0em 2em 0em',
    justifyItems: 'center',
  },
  contentourStrengthsHead: {
    fontSize: '1.5rem',
    fontWeight: '600',
    textAlign: 'left',
    color: '#080c2d',
    margin: '10px 0px 0px 0px ',
    letterSpacing: '0.05rem',
  },
  contentourStrengthsHeadContent: {
    fontSize: '1.1rem',
    fontWeight: '600',
    textAlign: 'left',
    color: '#080c2d',
    margin: '10px 0px 0px 0px ',
    letterSpacing: '0.02rem',
  },
  contentourStrengthsContent: {
    fontSize: '1.0rem',
    fontWeight: '400',
    textAlign: 'left',
    color: '#545372',
    margin: '10px 0px 0px 0px ',
    letterSpacing: '0.02rem',
  },
  contentourStrengthsFooter: {
    fontSize: '1.0rem',
    fontWeight: '600',
    textAlign: 'left',
    color: '#545372',
    margin: '30px 0px 0px 0px ',
    letterSpacing: '0.02rem',
  },

  regButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '12px',
  },
  bannerBox: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  iMacContainer: {
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    '@media (max-width:600px)': {
      display: 'none',
    },
  },
  boxButtonText: {
    margin: '24px 0px  0px 0px',
  },

  boxContentHead: {
    minHeight: '600px',
    minWidth: 'auto',
    maxWidth: '850px',
    width: '100%',
    backgroundColor: 'rgba(3, 41, 99, 0.65)',
    flexFlow: 'wrap',
    padding: '20px 10px 0px 20px',
    borderRadius: '6px',
    zIndex: 4,
  },

  homeImagesCalculatorText: {
    fontStyle: 'normal',
    textAlign: 'left',
    fontSize: '0.8rem',
    fontWeight: '400',
    color: COLOR_WHITE,
    marginTop: '10px',
    padding: '0px 0px 0px 0px',
  },
  contentButtonPricesCalculator: {
    fontStyle: 'normal',
    textAlign: 'left',
    fontSize: '0.9rem',
    fontWeight: '400',
    color: '#032963',
    marginTop: '10px',
    padding: '0px 0px 0px 0px',
  },

  siteTitleContent: {
    fontSize: '1.2rem',
    fontWeight: '400',
    color: COLOR_WHITE,
  },

  siteTitleBold: {
    fontSize: '3.1rem',
    fontWeight: '800',
    color: COLOR_WHITE,
    letterSpacing: '0.1rem',
  },
  siteTitle: {
    fontSize: '1.95rem',
    fontWeight: '600',
    color: COLOR_WHITE,
  },

  contentspecialFeatures: {
    display: 'flex',
    textAlign: 'left',
    fontSize: '1.0rem',
    fontWeight: '400',
    color: '#080c2d',
    margin: '12px 2px 2px 2px ',
    letterSpacing: '0.02rem',
  },

  videoWrap: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '900px',
    margin: 'auto',

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginLeft: '32px',
      marginRight: '32px',
    },
    '& video': {
      borderRadius: '16px',
      marginTop: '24px',
      boxShadow:
        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },

  dotSpecialFeatures: {
    color: '#3e7f35',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginRight: '10px',
    marginTop: '2px',
    margin: '0px 0px 0px 0px ',
  },
  contentspecialFeaturesbutton: {
    marginTop: '40px',
    paddingBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  accordion: {
    // color: '#3e7f35',
    backgroundColor: COLOR_WHITE,
    margin: '0px 0px 0px 0px ',
    borderStyle: 'none',
    borderBottom: '0px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',

    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },

  headingAccordionHomePage: {
    fontSize: '1.0rem',
    flexBasis: '65%',
    flexShrink: 0,
    color: '#080c2d',
    fontWeight: '500',
    letterSpacing: '0.03rem',
  },

  secondaryContentfeature: {
    width: '100%',
    fontSize: '1.0rem',
    color: '#545372',
    backgroundColor: COLOR_WHITE,
    padding: '10px 30px 0px 50px ',
    letterSpacing: '0.03rem',
  },

  costBoxContentTop: {
    fontSize: '0.8rem',
    fontWeight: '400',
    letterSpacing: '0.05rem',
    textAlign: 'left',
    padding: '8px 10px 0px 10px',
  },
  costBoxContentFooter: {
    fontSize: '0.8rem',
    fontWeight: '400',
    textAlign: 'left',
    padding: '0px 10px 0px 10px',
  },
  costBoxContent: {
    fontSize: '0.8rem',
    fontWeight: '400',
    letterSpacing: '0.05rem',
    textAlign: 'left',
    padding: '0px 10px 0px 10px',
  },
  costBoxContentHeadFooterresult: {
    fontSize: '1.2rem',
    fontWeight: '600',
    letterSpacing: '0.07rem',
    textAlign: 'center',
    margin: '5px 0px 10px 0px ',
    color: COLOR_WHITE,
  },
  costBoxContentHeadFooter: {
    fontSize: '1.0rem',
    fontWeight: '600',
    letterSpacing: '0.05rem',
    textAlign: 'center',
    margin: '5px 0px 5px 0px ',
    color: '#080c2d',
  },
  costBoxContentHead: {
    fontSize: '0.9rem',
    fontWeight: '400',
    textAlign: 'center',
    marginBottom: '5px',
  },
  costBoxHead: {
    fontSize: '1.5rem',
    fontWeight: '600',
    letterSpacing: '0.06rem',
    textAlign: 'center',
    marginBottom: '5px',
  },
  boxContentHeadFooterresult: {
    border: '1px solid #cacece',
    borderRadius: '3px',
    backgroundColor: '#3e7f35',
    margin: '10px 20px 0px 10px',
    height: '42px',
  },

  boxProductionCost: {
    backgroundColor: COLOR_WHITE,
    borderRadius: '5px',
    padding: '10px 10px 10px 10px',
    maxWidth: '100%',
    minWidth: '100%',
  },

  boxleft: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'left',
  },
  boxright: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'right',
  },
  resultRed: {
    color: 'red',
    fontSize: '1.0rem',
    fontWeight: '600',
    padding: '0px 20px 0px 0px',
  },
  resultGreen: {
    color: 'green',
    fontSize: '1.0rem',
    fontWeight: '600',
    padding: '0px 20px 0px 0px',
  },
  boxCalculatorcontent: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    margin: '2px 0px 20px 0px ',
    alignItems: 'flex-center',
  },
  boxCalculator: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '20px 0px 20px 0px',
    alignItems: 'flex-center',
  },

  dividerCostBox: {
    margin: '0px 15px 0px 10px',
  },

  resultContent: {
    fontSize: '0.85rem',
    fontWeight: '400',
    letterSpacing: '0.05rem',
    textAlign: 'left',
    padding: '0px 0px 0px 10px',
  },
  resultBoxCost: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },

  laptopPageHead: {
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: '600',
    color: '#032963',
    marginTop: '5px',
    letterSpacing: '0.05rem',
  },

  laptopPageContent: {
    textAlign: 'center',
    fontSize: '1.0rem',
    fontWeight: '400',
    color: '#545372',
    marginTop: '10px',
    padding: '0px 0px 0px 0px',
    letterSpacing: '0.05rem',
  },

  readMore: {
    fontSize: '0.65rem',
    color: '#918f8f',
    margin: '0rem 0px -8px 0px',
    letterSpacing: '0.02rem',
  },
  secondaryContentCost: {
    width: '100%',
    fontSize: '1.0rem',
    color: '#545372',
    backgroundColor: COLOR_WHITE,
    fontWeight: '400',
    padding: '10px 50px 30px 35px ',
    letterSpacing: '0.03rem',
  },
  secondaryContentBold: {
    width: '100%',
    fontSize: '1.0rem',
    color: '#545372',
    backgroundColor: COLOR_WHITE,
    fontWeight: '600',
    padding: '10px 0px 0px 0px ',
    letterSpacing: '0.03rem',
  },
  headImagesTextPosition: {
    display: 'inline-flex',
    maxWidth: '1440px',
    padding: '4em 1em 0em 1em',
    marginTop: '120px',
  },

  iconcost: {
    color: '#545372',
    height: '1.05rem',
  },
  timelineDotImacGreen: {
    background: '#263d86',
    height: '14px',
    width: '14px',
    marginRight: '10px',
    marginTop: '7px',
    borderBottomStyle: 'solid',
    borderColor: '#efefef',
  },
  timeLineContentImacGreen: {
    display: 'flex',
    textAlign: 'left',
    fontSize: '1.0rem',
    fontWeight: '400',
    color: '#545372',
    margin: '2px 2px 2px 2px ',
    letterSpacing: '0.02rem',
  },
}));
