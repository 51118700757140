import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { useHomePageStyles } from './styles';

const LinkText = styled(Link)`
  display: inline-block;
  z-index: -2;
  text-decoration: none;
  color: #ea5e2a;

  &:hover {
    color: #ea5e2a;
    text-decoration: none;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    text-decoration: none;
  }
`;

const ContentContainerInfo = () => {
  const { t } = useTranslation();
  const classes = useHomePageStyles();
  return (
    <>
      <Box className={classes.mastheadlightblue}>
        <Grid container spacing={4} className={classes.homeContainerBlueMedium}>
          <Grid item lg={12} md={12} xs={12}>
            <Typography className={classes.contentReportLeft}>
              <Box mt={3}>
                {t('trans.contentContainerInfoLinkHighlightsContent')}{' '}
                <LinkText to="/product">
                  <span> {t('trans.contentContainerInfoLinkHighlights')}</span>
                </LinkText>
              </Box>

              <Box mt={0}>
                {t('trans.contentContainerInfoLinkFaqContentOne')}{' '}
                <LinkText to="/faq">
                  <span>{t('trans.contentContainerInfoLinkFaq')}</span>
                </LinkText>{' '}
                {t('trans.contentContainerInfoLinkFaqContentTwo')}{' '}
                <LinkText to="/contact">
                  <span>{t('trans.contentContainerInfoLinkContact')}</span>
                </LinkText>{' '}
                {t('trans.contentContainerInfoLinkContactContent')}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ContentContainerInfo;
