import React from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';

import { ListingDot } from '../ListingDot/ListingDot';
import { useHomePageStyles } from './styles';
import { ListingDotBlue } from '../ListingDotBlue/ListingDotBlue';

const Accordion = withStyles({
  root: {
    borderBottom: '0px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',

    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '10px auto auto auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: '2px solid #ebf0f4',
    marginBottom: 0,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      backgroundColor: '#edeaea',
      borderBottom: '1px solid #e5e5e5',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0px',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    borderBottom: '12px solid  #ebf0f4',
    borderTop: '6px solid #ebf0f4',
  },
}))(MuiAccordionDetails);

const ExpandMoreIconnew = styled(ExpandMoreIcon)`
  color: #ea5e2a;
`;

const LinkText = styled(Link)`
  display: inline-block;
  z-index: -2;
  text-decoration: none;
  color: #ea5e2a;

  &:hover {
    color: #ea5e2a;
    text-decoration: none;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    text-decoration: none;
  }
`;
const CostsAccordion = ({ location }) => {
  const { t } = useTranslation();
  const classes = useHomePageStyles();

  // if location hash is string, remove hash sign (#),
  // replace bh-header and set that value as initial value, else set the initial value to false
  const hash = location?.hash;

  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    if (typeof hash === 'string') {
      setTimeout(() => {
        setExpanded(hash.slice(1));
      }, [200]);
    }
  }, [hash]);

  const handleChange = (panel) => {
    // set to false if passed panel equals to currently opened panel. (want to close the panel)
    setExpanded((currentPanel) => (currentPanel === panel ? false : panel));
  };
  return (
    <>
      <Box mt={0}>
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel7'}
          onChange={() => handleChange('panel7')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel7bh-content" id="panel7bh-header">
            <ListingDot title="" listItems={['']} />
            <Box>
              <Typography className={classes.headingAccordionHomePage}>{t('trans.costsArcordiumHead7')}</Typography>
              <Typography className={classes.readMore}>{t('trans.costsCalculatorReadMore')}</Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Typography className={classes.secondaryContentCost}>
              {t('trans.costsArcordiumContent7_1')}
              <Box mt={1}>{t('trans.costsArcordiumContent7_2')}</Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel10'}
          onChange={() => handleChange('panel10')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel10bh-content" id="panel10bh-header">
            <ListingDot title="" listItems={['']} />
            <Box>
              <Typography className={classes.headingAccordionHomePage}>{t('trans.costsArcordiumHead10')}</Typography>
              <Typography className={classes.readMore}>{t('trans.costsCalculatorReadMore')}</Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Typography className={classes.secondaryContentCost}>
              {t('trans.costsArcordiumContent10_1')}
              <Box mt={1}>{t('trans.costsArcordiumContent10_2')}</Box>
              <Box mt={0}>{t('trans.costsArcordiumContent10_3')}</Box>
              <Box mt={0}>{t('trans.costsArcordiumContent10_4')}</Box>

              <Box mt={2}>
                {t('trans.costsArcordiumContent210')}{' '}
                <LinkText to="/product/" title="Highlights und viel Wissenswertes">
                  {t('trans.costsArcordiumContent210_Link')}
                </LinkText>
                {t('trans.costsArcordiumContent215')}
              </Box>
              <Box>
                {t('trans.costsArcordiumContent220')}{' '}
                <LinkText to="/prices/" title="Preisplan">
                  {t('trans.costsArcordiumContent220_Link')}
                </LinkText>
                {t('trans.costsArcordiumContent225')}
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
        <Box id="panel20" />
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel20'}
          onChange={() => handleChange('panel20')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel20bh-content" id="panel20bh-header">
            <ListingDot title="" listItems={['']} />
            <Box>
              <Typography className={classes.headingAccordionHomePage}>{t('trans.costsArcordiumHead20')}</Typography>
              <Typography className={classes.readMore}>{t('trans.costsCalculatorReadMore')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContentCost}>
              {t('trans.costsArcordiumContent20_1')}
              <Box mt={2}>{t('trans.costsArcordiumContent20_2')}</Box>
              <Box mt={2}>{t('trans.costsArcordiumContent20_3')}</Box>
              <Box mt={0}>{t('trans.costsArcordiumContent20_4')}</Box>
              <Box mt={2}>
                {t('trans.costsArcordiumContent210')}{' '}
                <LinkText to="/product/" title="Highlights und viel Wissenswertes">
                  {t('trans.costsArcordiumContent210_Link')}
                </LinkText>
                {t('trans.costsArcordiumContent215')}
              </Box>
              <Box>
                {t('trans.costsArcordiumContent220')}{' '}
                <LinkText to="/prices/" title="Preisplan">
                  {t('trans.costsArcordiumContent220_Link')}
                </LinkText>
                {t('trans.costsArcordiumContent225')}
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel30'}
          onChange={() => handleChange('panel30')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel30bh-content" id="panel30">
            <ListingDot title="" listItems={['']} />
            <Box>
              <Typography className={classes.headingAccordionHomePage}>{t('trans.costsArcordiumHead30')}</Typography>
              <Typography className={classes.readMore}>{t('trans.costsCalculatorReadMore')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContentCost}>
              {t('trans.costsArcordiumContent30_1')}
              <Box mt={1}>{t('trans.costsArcordiumContent30_2')}</Box>
              <Box mt={2}>
                {t('trans.costsArcordiumContent220')}{' '}
                <LinkText to="/prices/" title="Preisplan">
                  {t('trans.costsArcordiumContent220_Link')}
                </LinkText>
                {t('trans.costsArcordiumContent225')}
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
        <Box id="panel40" />
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel40'}
          onChange={() => handleChange('panel40')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel40bh-content" id="panel40bh-header">
            <ListingDot title="" listItems={['']} />
            <Box>
              <Typography className={classes.headingAccordionHomePage}>{t('trans.costsArcordiumHead40')}</Typography>
              <Typography className={classes.readMore}>{t('trans.costsCalculatorReadMore')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContentCost}>
              {t('trans.costsArcordiumContent40_1')}
              <Box mt={1}>{t('trans.costsArcordiumContent40_2')}</Box>
              <Box mt={1}>{t('trans.costsArcordiumContent40_3')}</Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
        {/* -------------------------------------------------------- */}
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel45'}
          onChange={() => handleChange('panel45')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel45bh-content" id="panel45">
            <ListingDot title="" listItems={['']} />
            <Box>
              <Typography className={classes.headingAccordionHomePage}>{t('trans.costsArcordiumHead45')}</Typography>
              <Typography className={classes.readMore}>{t('trans.costsCalculatorReadMore')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContentCost}>
              {t('trans.costsArcordiumContent45_1')}
              <Box mt={1}>{t('trans.costsArcordiumContent45_2')}</Box>
              <Box mt={1}>{t('trans.costsArcordiumContent45_3')}</Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}

        <Box id="panel50" />
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel50'}
          onChange={() => handleChange('panel50')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel50bh-content" id="panel50bh-header">
            <ListingDot title="" listItems={['']} />
            <Box>
              <Typography className={classes.headingAccordionHomePage}>{t('trans.costsArcordiumHead50')}</Typography>
              <Typography className={classes.readMore}>{t('trans.costsCalculatorReadMore')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContentCost}>
              <Box mt={1}>
                <Typography className={classes.secondaryContentBold}>
                  {t('trans.costsArcordiumContent50_1Head')}
                </Typography>
              </Box>
              {t('trans.costsArcordiumContent50_1')}
              <Box mt={1}>
                <Typography className={classes.secondaryContentBold}>
                  {t('trans.costsArcordiumContent50_2Head')}
                </Typography>
              </Box>
              <Box mt={0}>{t('trans.costsArcordiumContent50_2')}</Box>
              <Box mt={1}>{t('trans.costsArcordiumContent50_3')}</Box>
              <Box mt={1}>{t('trans.costsArcordiumContent50_4')}</Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}

        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel70'}
          onChange={() => handleChange('panel70')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel70bh-content" id="panel70bh-header">
            <ListingDot title="" listItems={['']} />
            <Box>
              <Typography className={classes.headingAccordionHomePage}>{t('trans.costsArcordiumHead70')}</Typography>
              <Typography className={classes.readMore}>{t('trans.costsCalculatorReadMore')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContentCost}>
              {t('trans.costsArcordiumContent70_1')}
              <Box mt={1}>{t('trans.costsArcordiumContent70_2')}</Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
        <Box id="panel90" />
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel90'}
          onChange={() => handleChange('panel90')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel90bh-content" id="panel90bh-header">
            <ListingDot title="" listItems={['']} />
            <Box>
              <Typography className={classes.headingAccordionHomePage}>{t('trans.costsArcordiumHead90')}</Typography>
              <Typography className={classes.readMore}>{t('trans.costsCalculatorReadMore')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContentCost}>{t('trans.costsArcordiumContent90_1')}</Typography>
          </AccordionDetails>
        </Accordion>

        {/* -------------------------------------------------------- */}
        <Box id="panel91" />
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel91'}
          onChange={() => handleChange('panel91')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel91bh-content" id="panel91bh-header">
            <ListingDot title="" listItems={['']} />
            <Box>
              <Typography className={classes.headingAccordionHomePage}>{t('trans.costsArcordiumHead91')}</Typography>
              <Typography className={classes.readMore}>{t('trans.costsCalculatorReadMore')}</Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Typography className={classes.secondaryContentCost}>
              <Box mt={1}>{t('trans.costsArcordiumContent91_1')}</Box>
              <Box mt={1}>{t('trans.costsArcordiumContent91_2')}</Box>
              <Box mt={2}>
                <ListingDotBlue
                  title=""
                  listItems={[
                    t('trans.costsArcordiumInstructionDetails-1'),
                    t('trans.costsArcordiumInstructionDetails-2'),
                    t('trans.costsArcordiumInstructionDetails-3'),
                    t('trans.costsArcordiumInstructionDetails-4'),
                    t('trans.costsArcordiumInstructionDetails-5'),
                    t('trans.costsArcordiumInstructionDetails-6'),
                    t('trans.costsArcordiumInstructionDetails-7'),
                  ]}
                />
              </Box>
              <Box mt={1}>{t('trans.costsArcordiumContent91_3')}</Box>
              <Box mt={1}>{t('trans.costsArcordiumContent91_4')}</Box>
              <Box mt={1}>{t('trans.costsArcordiumContent91_5')}</Box>
              <Box mt={1}>
                <Typography className={classes.secondaryContentBold}>{t('trans.costsArcordiumHead91_2')}</Typography>
              </Box>
              <Box mt={0}>{t('trans.costsArcordiumContent91_6')}</Box>
              <Box mt={1}>{t('trans.costsArcordiumContent91_7')}</Box>
              <Box mt={1}>{t('trans.costsArcordiumContent91_8')}</Box>
              <Box mt={1}>
                {t('trans.costsArcordiumContent210')}{' '}
                <LinkText to="/product/" title="Highlights und viel Wissenswertes">
                  {t('trans.costsArcordiumContent210_Link')}
                </LinkText>
                {t('trans.costsArcordiumContent215')}
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
      </Box>
    </>
  );
};

export default CostsAccordion;
