import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useHomePageStyles } from './styles';
import { FeatureBox } from '../FeatureBox/FeatureBox';

const FeatureList = () => {
  const { t } = useTranslation();
  const classes = useHomePageStyles();

  return (
    <Box className={classes.mastheadWhite}>
      <Grid container spacing={4} className={classes.homeContainerWhiteMedium}>
        <Grid item lg={12} md={12} xs={12}>
          <Typography className={classes.contentHeadCenter}>{t('trans.featureListHeadBlog')}</Typography>
          <Typography className={classes.contentHeadCategoryCenter}>{t('trans.featureListHeadContentBlog')}</Typography>
        </Grid>

        <FeatureBox
          title={t('trans.metaleletronics')}
          listItems={[
            t('trans.metaleletronicsPart1'),
            t('trans.metaleletronicsPart2'),
            t('trans.metaleletronicsPart3'),
            t('trans.metaleletronicsPart4'),
            t('trans.metaleletronicsPart5'),
            t('trans.metaleletronicsPart6'),
          ]}
        />
        <FeatureBox
          title={t('trans.transport & logistics')}
          listItems={[
            t('trans.transport & logisticsPart1'),
            t('trans.transport & logisticsPart2'),
            t('trans.transport & logisticsPart3'),
            t('trans.transport & logisticsPart4'),
            t('trans.transport & logisticsPart5'),
            t('trans.transport & logisticsPart6'),
            t('trans.transport & logisticsPart7'),
            t('trans.transport & logisticsPart8'),
          ]}
        />

        <FeatureBox
          title={t('trans.economy & politics')}
          listItems={[
            t('trans.economy & politicsPart1'),
            t('trans.economy & politicsPart2'),
            t('trans.economy & politicsPart3'),
            t('trans.economy & politicsPart4'),
            t('trans.economy & politicsPart5'),
          ]}
        />

        <FeatureBox
          title={t('trans.tourism & gasrtronomy')}
          listItems={[
            t('trans.tourism & gasrtronomyPart1'),
            t('trans.tourism & gasrtronomyPart2'),
            t('trans.tourism & gasrtronomyPart3'),
            t('trans.tourism & gasrtronomyPart4'),
            t('trans.tourism & gasrtronomyPart5'),
          ]}
        />

        <FeatureBox
          title={t('trans.pharma & health')}
          listItems={[
            t('trans.pharma & healthPart1'),
            t('trans.pharma & healthPart2'),
            t('trans.pharma & healthPart3'),
            t('trans.pharma & healthPart4'),
            t('trans.pharma & healthPart5'),
            t('trans.pharma & healthPart6'),
          ]}
        />

        <FeatureBox
          title={t('trans.trade')}
          listItems={[
            t('trans.tradePrart1'),
            t('trans.tradePrart2'),
            t('trans.tradePrart3'),
            t('trans.tradePrart4'),
            t('trans.tradePrart5'),
            t('trans.tradePrart6'),
          ]}
        />
      </Grid>
    </Box>
  );
};

export default FeatureList;
