import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { Box, Grid, Hidden, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import WaveBottomRight from '../imagesSettings/Wave/waveBottomRight';
import { Imac } from '../IMac/iMacDashboard';

import JustCreate from './justCreate';
import OurStrengths from './ourStrengths';
import LaptopPage from './laptop';
import ContentContainerInfo from './contentContainerInfo';
import CalculatorButton from '../Button/calculatorButton';
import { useHomePageStyles } from './styles';
import SpecialFeatures from './specialFeatures';
import Features from './features';
import Costs from './costs';
import FeatureList from './featureList';

export const Home = (props) => {
  const { backgroundImageHero } = useStaticQuery(
    graphql`
      query {
        backgroundImageHero: file(relativePath: { eq: "heroBackground.jpeg" }) {
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `,
  );

  const { location } = props;

  const pluginImage = getImage(backgroundImageHero);
  const { t } = useTranslation();
  const classes = useHomePageStyles();

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <BgImage image={pluginImage} className={classes.headImagesPosition}>
        <Grid container spacing={5} className={classes.headImagesTextPosition}>
          <Grid item lg={6} md={7} sm={12} xs={12} className={classes.bannerBox}>
            <Box className={classes.boxContentHead}>
              <Typography variant="h1" className={classes.siteTitleBold}>
                {t('trans.homeImagesHead')}
              </Typography>
              <Box>
                <Typography variant="h1" className={classes.siteTitle}>
                  {t('trans.homeImagesContentOne')}
                </Typography>
              </Box>

              <Box mt={3}>
                <Typography className={classes.siteTitleContent}>{t('trans.homeImagesContentThree')}</Typography>
              </Box>
              <Box mt={3}>
                <Typography className={classes.siteTitleContent}>{t('trans.homeImagesContentFour')}</Typography>
              </Box>
              <Box className={classes.boxButtonText}>
                <Typography className={classes.homeImagesCalculatorText}>
                  {t('trans.homeImagesCalculatorText')}
                </Typography>
              </Box>

              <Box className={classes.regButton} position="relative" zIndex={6}>
                <CalculatorButton />
              </Box>

              <WaveBottomRight />
            </Box>
          </Grid>

          <Hidden smDown>
            <Grid item lg={6} md={5} className={classes.iMacContainer}>
              <Box>
                <Imac scale={isMedium ? 1.3 : 1.85} />
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </BgImage>

      {/* -------------------------------------------------------- */}

      <SpecialFeatures />
      {/* Container - Die Software für ihre Unterweisungen ... */}

      <LaptopPage />
      {/* Leistungsstarke Lösungen für Ihre Online-Unterweisungen */}

      <OurStrengths />
      {/* Container - Profitieren Sie von unseren Stärken */}

      <JustCreate />
      {/* Wie das geht? Ganz einfach! */}

      <Features />
      {/* Container - Erstellen Sie Unterweisungen, Schulungen, Tutorials und mehr */}

      <Costs location={location} />
      {/* Warum sollten Sie sich für UMO entscheiden? */}

      <FeatureList />
      {/* Container - UMO können Sie nahezu in jeder Branche einsetzen */}

      <ContentContainerInfo />
      {/* Welche Vorteile bietet Ihnen UMO – der Unterweisungsmanager-Online? */}

      {/* -------------------------------------------------------- */}

      {/*
      <Container>
        <Box className="masthead2 ">
          <SpecialFeatures />
        </Box>
      </Container>
      <LaptopPage />
      <Container>
        <Box className="masthead2 ">
          <OurStrengths />
        </Box>
      </Container>
      <Box className="masthead2 mastheadlightblue" id="how-to-register">
        <JustCreate />
      </Box>
      <Container>
        <Box className="masthead2 ">
          <Features />
        </Box>
      </Container>
      <Box className="masthead2 mastheadlightblue">
        <Costs location={location} />
      </Box>
      <Container>
        <Box className="masthead2 ">
          <FeatureList />
        </Box>
      </Container>
      <Box className="masthead2 mastheadlightblue">
        <ContentContainerInfo />
      </Box>
      */}
    </>
  );
};
