import React from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import ImagesTourLaptop from '../imagesSettings/Tour/imagesTourLaptop';
import CalculatorButton from '../Button/calculatorButton';
import { useHomePageStyles } from './styles';
import { ListingDot } from '../ListingDot/ListingDot';

const LaptopPage = () => {
  const { t } = useTranslation();
  const classes = useHomePageStyles();
  return (
    <>
      <Box className={classes.mastheadlightblueLong}>
        <Grid id="specialFeatures" container spacing={4} className={classes.homeContainerBlueWide}>
          <Grid item lg={12} md={12} xs={12}>
            <Box mt={1}>
              <Typography className={classes.contentHeadCenter}>{t('trans.laptopHead')}</Typography>
            </Box>
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <Box>
              <ImagesTourLaptop />
            </Box>
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <Box ml={0}>
              <ListingDot
                title=""
                listItems={[
                  t('trans.laptopNumer1'),
                  t('trans.laptopNumer2'),
                  t('trans.laptopNumer3'),
                  t('trans.laptopNumer4'),
                  t('trans.laptopNumer5'),
                  t('trans.laptopNumer6'),
                  t('trans.laptopNumer7'),
                  t('trans.laptopNumer8'),
                  t('trans.laptopNumer9'),
                ]}
              />
              {/* {t('trans.laptopNumer2')} */}

              <Box mt={2}>
                <Typography className={classes.contentButtonPricesCalculator}>
                  {t('trans.laptopContentButton')}
                </Typography>
                <CalculatorButton />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xs={12}>
          <Paper className={classes.infoPaperBox}>
            <Box>
              <Typography className={classes.laptopPageHead}>{t('trans.laptopBoxContentOne')}</Typography>
              <Typography className={classes.laptopPageContent}>{t('trans.laptopBoxContentTwo')}</Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default LaptopPage;
